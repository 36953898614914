@import "~antd/dist/antd.css";

.ant-table-tbody > tr.ant-table-row-success > td {
  background: #e6ffe8;
}

.ant-table-tbody > tr.ant-table-row-success:hover > td {
  background: rgba(230, 255, 232, 0.5) !important;
}

.ant-table-tbody > tr.ant-table-row-warning > td {
  background: #fffbe6;
}

.ant-table-tbody > tr.ant-table-row-warning:hover > td {
  background: rgba(255, 251, 230, 0.5) !important;
}

.ant-table-tbody > tr.ant-table-row-failure > td {
  background: #ffe8e6;
}

.ant-table-tbody > tr.ant-table-row-failure:hover > td {
  background: rgba(255, 232, 230, 0.5) !important;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
